import { transactionStoreTypes } from "../store/types";
import { store } from "../store/index";
import HttpRequest from "./httpRequests";
import url from "./url";
import { TransactionType } from "../common/utils/helper";

const { dispatch } = store;
const Request = new HttpRequest();

const modifyTransactionType = (tnx) => {
  const newTxn = tnx.map((item) => {
    if (item.type === TransactionType.WALLET_TOP_UP) {
      return { friendly: "Payment Via Transfer", type: item.type };
    }
    return item;
  });
  return newTxn;
};

const commercialBankTransactionType = (list) => {
  const newTransactionType = [];

  for (let i = 0; i < list.length; i++) {
    const type = list[i].type;
    switch (type) {
      case TransactionType.WITHDRAWAL:
        newTransactionType.push({ friendly: "POS Purchase", type });
        break;
      case TransactionType.WALLET_TOP_UP:
        newTransactionType.push({ friendly: "Payment Via Transfer", type });
        break;
      case TransactionType.PAYOUT:
        newTransactionType.push({ friendly: "Payout", type });
        break;
      case TransactionType.SETTLEMENT:
        newTransactionType.push({ friendly: "Settlement", type });
        break;
      case TransactionType.NAIRA_INTL_WITHDRAWAL:
        newTransactionType.push({ friendly: "Naira Intl. Purchase", type });
        break;
      case TransactionType.USD_INTL_WITHDRAWAL:
        newTransactionType.push({ friendly: "USD Intl. Purchase", type });
        break;
      // no default
    }
  }

  return newTransactionType;
};

export const fetchTransactionStats = async (query) => {
  try {
    const statsResponse = await Request.get(`/${url.transaction}/stats`, query);
    return Promise.resolve(statsResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const fetchTransactionMetrics = async (query) => {
  try {
    const metricsResponse = await Request.get(
      `/${url.transaction}/metrics`,
      query
    );
    return Promise.resolve(metricsResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.response,
    });
  }
};

export const fetchAllTransactions = async (query) => {
  try {
    const response = await Request.get(`/${url.transaction}`, query);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Faied to fetch transaction",
    });
  }
};

export const fetchTransactionDetails = async (ref, query) => {
  try {
    const tnxResponse = await Request.get(
      `/${url.transaction}/${ref}/details`,
      query
    );
    return Promise.resolve(tnxResponse);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message || "Failed to fetch transaction detail",
    });
  }
};

export const fetchTransactionTypes = async (isEnabled) => {
  try {
    const tnxTypeResponse = await Request.get(`/${url.transaction}/type`);
    if (isEnabled) {
      const result = commercialBankTransactionType(tnxTypeResponse.data);
      dispatch({
        type: transactionStoreTypes.SET_TRANSACTION_TYPES,
        payload: result,
      });
    } else {
      const result = modifyTransactionType(tnxTypeResponse.data);
      dispatch({
        type: transactionStoreTypes.SET_TRANSACTION_TYPES,
        payload: result,
      });
    }
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const fetchTransactionStatus = async () => {
  try {
    const tnxStatusResponse = await Request.get(`/${url.transaction}/status`);
    dispatch({
      type: transactionStoreTypes.SET_TRANSACTION_STATUS,
      payload: tnxStatusResponse.data,
    });
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const downloadReport = async (query, data) => {
  try {
    const reportDownload = await Request.post(
      `/${url.transaction}/download`,
      query,
      data
    );
    return Promise.resolve(reportDownload);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message ||
        "Failed to download transaction report",
    });
  }
};

export const sendTransactionReceipt = async (data) => {
  try {
    const receiptResponse = await Request.post(
      `/${url.transaction}/receipt`,
      null,
      data
    );
    return Promise.resolve(receiptResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const validateAccount = async (data) => {
  try {
    const accountResponse = await Request.post(
      `/${url.transaction}/validate-account`,
      null,
      data
    );
    return Promise.resolve(accountResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const getTransactionServiceFee = async (data) => {
  try {
    const feeResponse = await Request.post(`/service-fee`, null, data);
    return Promise.resolve(feeResponse);
  } catch (error) {
    return Promise.reject({
      statusCode: error.response.status,
      message: error.response?.data?.message || error.message,
    });
  }
};

export const transferFunds = async (option, data) => {
  try {
    const feeResponse = await Request.post(
      `/${url.transaction}`,
      { option: option },
      data
    );
    return Promise.resolve(feeResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const transferToWallet = async (option, data, query) => {
  try {
    const feeResponse = await Request.post(
      `/${url.transaction}/${option}/withdraw`,
      query,
      data
    );
    return Promise.resolve(feeResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const validateCustomerWalletNumber = async (phoneNumber) => {
  try {
    const validResponse = await Request.get(
      `/${url.transaction}/${phoneNumber}/customer`
    );
    return Promise.resolve(validResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const creditCustomer = async (data) => {
  try {
    const creditResponse = await Request.post(
      `/${url.transaction}/customer-credit`,
      null,
      data
    );
    return Promise.resolve(creditResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const repushTransaction = async (reference) => {
  try {
    const repushResponse = await Request.post(
      `/${url.transaction}/${reference}/push`
    );
    return Promise.resolve(repushResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const fetchLinkedSettlement = async (ref) => {
  try {
    const settlementTnxResponse = await Request.get(
      `/${url.transaction}/${ref}/settlement`
    );
    return Promise.resolve(settlementTnxResponse);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message ||
        "Failed to fetch settlment transaction",
    });
  }
};

export const fetchLinkedCommission = async (ref) => {
  try {
    const tnxResponse = await Request.get(
      `/${url.transaction}/${ref}/commission`
    );
    return Promise.resolve(tnxResponse);
  } catch (error) {
    if (error.response)
      return Promise.reject({ message: error.response?.data?.message });
    return Promise.reject(error);
  }
};

export const fetchLinkedDisbursement = async (ref) => {
  try {
    const tnxResponse = await Request.get(
      `/${url.transaction}/${ref}/disbursement`
    );
    return Promise.resolve(tnxResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const fetchCustomerTransactionStats = async (phone, query) => {
  try {
    const response = await Request.get(
      `/${url.transaction}/${phone}/customer-stats`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};

export const bulkTransactionRepush = async (data) => {
  try {
    const response = await Request.post(
      `/${url.transaction}/repush`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getBankNetworkList = async () => {
  try {
    const listResponse = await Request.get(`/${url.transaction}/bank-metrics`);
    return Promise.resolve(listResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const getStatsByTransactionType = async (query, option) => {
  try {
    const statsResponse = await Request.get(
      `/${url.transaction}/${option}/stats`,
      query
    );
    return Promise.resolve(statsResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Failed to fetch resource",
    });
  }
};

export const fetchBanks = async () => {
  try {
    const bankResponse = await Request.get(`/${url.transaction}/banks`);
    dispatch({
      type: transactionStoreTypes.SET_BANK_LIST,
      payload: bankResponse.data,
    });
    return Promise.resolve(bankResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || error.message,
    });
  }
};
