import React, { useEffect, useRef, useState } from "react";
import ModalShell from "../Modal/ModalShell";
import IconSvg from "../../../assets/svg-v2/transfer.svg";
import { useSelector } from "react-redux";
import {
  fetchTransactionDetails,
  repushTransaction,
} from "../../../api/transactionApi";
import { notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Skeleton from "react-loading-skeleton";
import { TransactionStatus } from "../Status/NewStatus";
import { LightButton, PrimaryButton } from "../Buttons/Button";
import {
  TransactionType,
  capitalize,
  copyText,
  formatCurrency,
} from "../../utils/helper";
import LocatorIcon from "../Icons/DashboardFilled";
import { useHistory } from "react-router-dom";
import copy from "../../../assets/svg-v2/link-copy.svg";
import { UserRole } from "../../utils/constants";

const ColumnDetail = ({ title, data }) => {
  return (
    <div>
      <p className="text-[#475467]">
        {title === "Status" ? "Transaction Status" : title}
      </p>
      {title === "Status" ? (
        <div className="mt-1">
          <TransactionStatus className="mt-4 capitalize" status={data} />
        </div>
      ) : (
        <p className="text-[#021230] font-bold">{data}</p>
      )}
    </div>
  );
};

const ColumnSkeleton = () => {
  return (
    <div>
      <Skeleton width={120} />
      <Skeleton width={120} />
    </div>
  );
};

const TransactionDetail = ({
  record,
  show,
  close,
  openModal,
  shouldHide,
  onSuccess,
}) => {
  const mounted = useRef(false);
  const user = useSelector((s) => s.auth.user);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [{ gatewayResponse, isWtihdrawal }, setResponse] = useState({
    gatewayResponse: {},
    isWtihdrawal: false,
  });
  const canReport =
    (record?.customerModelId || details?.Type === TransactionType.WITHDRAWAL) &&
    !loading;
  const history = useHistory();
  const gateway = Object.keys(gatewayResponse);
  const transactionRef = record?.walletReference || details["Reference"];
  const canSeeTerminal = isWtihdrawal && user?.role === UserRole.ADMIN;

  useEffect(() => {
    mounted.current = true;
    async function fetchData() {
      setLoading(true);
      try {
        const transactionResponse = await fetchTransactionDetails(
          record.reference,
          {
            timeCreated: record.timeCreated,
          }
        );
        if (mounted) {
          setResponse({
            gatewayResponse: transactionResponse.data["Gateway Response"] || {},
            isWtihdrawal:
              transactionResponse.data["Type"] === TransactionType.WITHDRAWAL,
          });
          delete transactionResponse.data["Gateway Response"];
          delete transactionResponse.data["canReprocess"];
          delete transactionResponse.data["Original Amount"];
          setDetails({
            ...transactionResponse.data,
            Amount: formatCurrency(transactionResponse.data["Amount"]),
            "Amount Charged": formatCurrency(
              transactionResponse.data["Amount Charged"]
            ),
          });
        }
      } catch (error) {
        notification.error({ message: "Error!", description: error.message });
      }
      setLoading(false);
    }
    fetchData();
    return () => {
      mounted.current = false;
    };
  }, [record]);

  async function handleRepushTransaction() {
    setIsLoading(true);
    try {
      const response = await repushTransaction(record.reference);
      onSuccess({
        title: "Success",
        message: response.data,
      });
    } catch (error) {
      notification.error({ message: "Error!", description: error.message });
    }
    setIsLoading(false);
  }

  return (
    <ModalShell
      style={{
        padding: "0px 20px 20px",
      }}
      width={600}
      show={show}
      cancel={close}
      closeIcon={<CloseOutlined className="text-white" />}
      className="new-modal"
      title={
        <div className="bg-[#021230] rounded-tr-lg rounded-tl-lg text-center p-3 flex flex-col items-center items-center gap-2">
          <h3 className="text-[18px] text-white font-bold mt-2 capitalize">
            {loading ? <Skeleton width={120} /> : details["Recipient Name"]}
          </h3>
          <p className="text-xs text-white">
            {loading ? <Skeleton width={120} /> : details["Transaction Date"]}
          </p>
        </div>
      }
    >
      <section>
        <div className="flex flex-col items-center gap-2 p-2 text-center">
          <img src={IconSvg} alt="transation-icon" width={50} />
          <h5 className="text-[#021230] text-[24px] font-bold">
            {loading ? <Skeleton width={120} /> : details["Amount"]}
          </h5>
          <div className="text-[#475467] -mt-2">
            <p className="mb-2 font-medium capitalize">
              {capitalize(details["Type"])} Reference
            </p>

            {loading ? (
              <Skeleton width={120} />
            ) : (
              <div className="flex gap-2 items-center">
                <p>{transactionRef}</p>
                <span role="button" onClick={() => copyText(transactionRef)}>
                  <img src={copy} alt="copy" />
                </span>
              </div>
            )}
          </div>
        </div>

        <section className="grid grid-cols-2 md:grid-cols-3 mt-5 gap-x-10 gap-y-5 md:py-5 md:pr-5 md:pl-10">
          {loading ? (
            [...Array(9)].map((item, index) => <ColumnSkeleton key={index} />)
          ) : details ? (
            Object.keys(details)?.map((item, i) => (
              <ColumnDetail key={item + i} data={details[item]} title={item} />
            ))
          ) : (
            <div>
              <p>Transaction Information not Available...</p>
            </div>
          )}
        </section>

        {!loading && gateway.length > 0 ? (
          <div className="md:py-5 md:pr-5 md:pl-10">
            <hr />
            <h3 className="text-[#021230] mt-5 font-bold"> Gateway Response</h3>
            <section
              className={`grid ${
                gateway.length > 1
                  ? "grid-cols-3"
                  : "grid-cols-1 max-h-40 hide-scroll-bar overflow-y-scroll"
              } gap-x-10 gap-y-5 mt-5`}
            >
              {gateway.map((item) => (
                <ColumnDetail
                  key={item}
                  data={gatewayResponse[item]}
                  title={item}
                />
              ))}
            </section>
          </div>
        ) : null}
      </section>

      <section
        className={`grid-cols-2 gap-4 p-5 ${shouldHide ? "hidden" : "grid"}`}
      >
        {canReport && (
          <LightButton
            className="button-shadow text-[#475467] font-bold rounded-lg"
            onClick={() => openModal(details, record?.customerModelId)}
          >
            Report Transaction
          </LightButton>
        )}
        {isWtihdrawal && (
          <PrimaryButton
            className="button-shadow !block text-[#475467] font-bold rounded-lg"
            onClick={handleRepushTransaction}
            loading={isLoading}
          >
            Repush Transaction
          </PrimaryButton>
        )}

        {canSeeTerminal && (
          <LightButton
            icon={<LocatorIcon />}
            className="button-shadow col-span-2 text-[#475467] font-bold rounded-lg"
            onClick={() => {
              history.push(`/terminal?id=${details["Biller ID"]}&tmsTab=2`);
            }}
          >
            Find Terminal Location
          </LightButton>
        )}
      </section>
    </ModalShell>
  );
};

export default TransactionDetail;
