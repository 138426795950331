import React, { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Checkbox, Form, notification } from "antd";
import { loginUser, setFormInput } from "../../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import AuthButton from "../../common/components/Buttons/AuthButton";
import { useFormValidation } from "../../common/utils/Hooks";
import { handleLoginError } from "../../common/utils/helper";
import { InputField } from "../../common/components/Input/InputField";
import { UserType } from "../../common/utils/constants";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const isAggregator = useMemo(
    () => window.location.hostname.includes(UserType.AGGREGATOR.toLowerCase()),
    []
  );
  const { valid, validate } = useFormValidation(form, []);
  const cachedUser = useSelector((state) => state.auth.cachedUser);
  const [checked, setChecked] = useState(Boolean(cachedUser));

  function handleCheckbox(event) {
    setChecked(event.target.checked);
  }

  const onFinish = async (values) => {
    const newValue = {
      ...values,
      domainType: isAggregator ? UserType.AGGREGATOR : UserType.BANKER,
    };

    try {
      setLoading(true);
      await loginUser(newValue, checked);
      notification["success"]({
        message: "Login Successful",
        duration: 5,
      });
      setTimeout(() => {
        history.push("/home");
      }, 100);
    } catch (err) {
      const { statusCode, error } = err;
      handleLoginError(statusCode, error, dispatch, history, newValue);
    }
    setLoading(false);
  };

  return (
    <section>
      <div>
        <h2 className="text-[#021230] text-4xl font-bold mb-2">
          Welcome back!
        </h2>
        <p className="text-base text-[#475467] mb-10">Sign in to continue</p>
      </div>
      <Form
        name="basic"
        onFinish={onFinish}
        layout="vertical"
        form={form}
        className="flex flex-col gap-1.5 min-h-[58vh] 2xl:min-h-[60vh]"
        onValuesChange={validate}
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          name="username"
          label="Username or Email"
          initialValue={cachedUser}
          rules={[
            {
              required: true,
              message: "Enter username!",
            },
          ]}
        >
          <InputField id="username" placeholder="Enter Username or Email" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Enter Password"
          rules={[{ required: true, message: "Enter password!" }]}
        >
          <InputField.Password />
        </Form.Item>

        <div className="flex flex-wrap justify-between gap-3 items-center mb-5">
          <div>
            <Checkbox checked={checked} onChange={handleCheckbox} />
            <span className="text-[#344054]"> Remember me</span>
          </div>
          <Link
            className="hover:text-[#26847D] text-[#26847D] font-semibold text-sm"
            to="/forgot-credential"
          >
            Forgot password or username
          </Link>
        </div>

        <div className="w-full my-auto mb-5">
          <AuthButton
            loading={loading}
            className=" w-full"
            htmlType="submit"
            disabled={!valid}
          >
            Log In
          </AuthButton>
        </div>

        {!isAggregator && (
          <div className="flex items-baseline justify-center gap-[4px] relative">
            <div className="text-[#475467]">New here?</div>

            <Link
              onClick={() => dispatch(setFormInput({}))}
              className="font-bold text-[#021230] hover:text-[#021230]"
              to="/signup-nationality"
            >
              Create an Account
            </Link>
          </div>
        )}
      </Form>
    </section>
  );
};

export default Login;
