import React from "react";
import { Switch } from "react-router-dom";
import PageFallback from "../components/Fallback/PageFallback";
import ProtectedRouted from "../routes/ProtectedRoute";
import { mainRoutes as routes } from "../routes/routes";
import AppShell from "./AppShell";

const MainContainer = () => {
  return (
    <Switch>
      <AppShell>
        <React.Suspense fallback={<PageFallback />}>
          {routes.map((route, i) => (
            <ProtectedRouted key={i} {...route} />
          ))}
        </React.Suspense>
      </AppShell>
    </Switch>
  );
};

export default MainContainer;
