import React from "react";
import ModalShell from "./ModalShell";
import { CloseOutlined } from "@ant-design/icons";
import TransactionSuccessIcon from "../../../assets/svg-v2/transaction-success-icon.svg";
import FailedIcon from "../../../assets/svg-v2/failed_money.svg";
import ReceiptIcon from "../../../assets/svg-v2/receipt-icon.svg";
import AuthButton from "../Buttons/AuthButton";
import { openTransactionModal } from "../../../api/walletApi";

export function TransactionStatusModal({ close, status, hasReference }) {
  const { open, message, description, isSuccess } = status;

  return (
    <ModalShell
      style={{
        padding: "0px 30px 30px",
      }}
      width={400}
      show={open}
      centered={false}
      cancel={close}
      destroyOnClose
      closeIcon={<CloseOutlined className="text-black" />}
      className="new-modal"
      title={
        <div className="flex justify-center rounded-tr-lg rounded-tl-lg text-center px-5 pt-5">
          <img
            src={isSuccess ? TransactionSuccessIcon : FailedIcon}
            alt="transaction-icon-progress"
          />
        </div>
      }
    >
      <section className="text-center mb-3">
        <h3 className="text-[18px] text-[#021230] font-medium">{message}</h3>
        <div
          className="text-[#475467] mb-3 font-bold"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {hasReference && (
          <div className="flex mt-7 justify-center">
            <button
              onClick={openTransactionModal}
              className="px-3 py-1.5 border border-[#F9DBAF] bg-[#FEF6EE] rounded-2xl flex items-center gap-2"
            >
              <img src={ReceiptIcon} alt="Receipt icon" />
              <span className="text-[#DC6803] font-medium text-sm ">
                View Receipt
              </span>
            </button>
          </div>
        )}

        <AuthButton className="mt-6 w-full" onClick={close}>
          Done
        </AuthButton>
      </section>
    </ModalShell>
  );
}
