import React from "react";
import ModalShell from "./ModalShell";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../api/authApi";
import { LightButton, PrimaryButton } from "../Buttons/Button";
import { CloseOutlined } from "@ant-design/icons";
import { LogoutIcon } from "../Icons/LogoutIcon";

const LogoutModal = ({ show, close }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  function logout() {
    dispatch(logoutUser(history));
  }

  return (
    <ModalShell
      style={{
        padding: "0px 30px 10px",
      }}
      width={400}
      show={show}
      cancel={close}
      closeIcon={<CloseOutlined className="text-black" />}
      className="new-modal"
      title={
        <div className="flex justify-center rounded-tr-lg rounded-tl-lg text-center px-5 pt-10">
          <div className="w-12 h-12 relative flex justify-center items-center bg-white rounded-lg shadow border border-slate-100 mb-2">
            <LogoutIcon />
          </div>
        </div>
      }
    >
      <section className="text-center mb-3">
        <h3 className="text-xl font-bold">Logout</h3>
        <p className="text-[#475467]">
          Are you sure you want to logout from Grupp?
        </p>
        <div className="flex gap-2 mt-8 items-center gap-3">
          <LightButton className="w-full" onClick={close}>
            Cancel
          </LightButton>
          <PrimaryButton className="w-full !bg-[#F04438]" onClick={logout}>
            Logout
          </PrimaryButton>
        </div>
      </section>
    </ModalShell>
  );
};
export default LogoutModal;
