import axios from "axios";
import url from "./url";
import { v4 } from "uuid";
import storage from "redux-persist/lib/storage";

class HttpRequests {
  header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
  };

  formDataHeader = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
  };

  request = axios.create({
    baseURL: url.base,
    headers: this.header,
  });

  requestWithoutHeader = axios.create({
    baseURL: url.base,
    headers: {
      "Content-Type": "application/json",
      browserId: this.setBrowserId(),
    },
  });

  requestBlob = axios.create({
    baseURL: url.base,
    headers: this.header,
    responseType: "blob",
  });

  requestFormData = axios.create({
    baseURL: url.base,
    headers: this.formDataHeader,
  });

  setBrowserId() {
    if (!localStorage.getItem("grupp.browserId")) {
      return localStorage.setItem("grupp.browserId", v4());
    }
    return localStorage.getItem("grupp.browserId");
  }

  clearData = () => {
    storage.removeItem("persist:banker-dashboard");
    storage.removeItem("grupp.token");
  };

  sendRequest = (url, config) => {
    this.request.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    return this.request(url, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          return Promise.reject("Request timed out:", err.message);
        }
        if (err.response && err.response.status === 401) {
          this.clearData();
          window.location.reload();
          return Promise.reject(err);
        }
        return Promise.reject(err);
      });
  };

  sendRequestWithoutHeader = async (url, config) => {
    return this.requestWithoutHeader(url, {
      ...config,
      headers: {
        browserId: this.setBrowserId(),
      },
    })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  sendFileRequest = (method, url, data) => {
    this.requestFormData.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    let config = { method: method, data: data };
    return this.requestFormData(url, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          this.clearData();
          window.location.reload();
          return Promise.reject(err);
        } else {
          return Promise.reject(err);
        }
      });
  };

  sendBlobRequest = (url) => {
    this.requestBlob.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    return this.requestBlob(url)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          this.clearData();
          window.location.reload();
          return Promise.reject(err);
        } else {
          return Promise.reject(err);
        }
      });
  };

  get = async (url, params, data, timeout) => {
    const config = { method: "GET", params, data, timeout: timeout };
    return this.sendRequest(url, config);
  };

  getBlob = async (url) => {
    return this.sendBlobRequest(url);
  };

  post = async (url, params, data) => {
    const config = { method: "POST", params, data };
    return this.sendRequest(url, config);
  };

  postWithoutHeader = async (url, data) => {
    const config = { method: "POST", data };
    return this.sendRequestWithoutHeader(url, config);
  };

  upload = async (url, data) => {
    return this.sendFileRequest("POST", url, data);
  };

  postFormData = async (url, data) => {
    return this.sendFileRequest("POST", url, data);
  };

  put = async (url, params, data) => {
    const config = { method: "PUT", params, data };
    return this.sendRequest(url, config);
  };

  delete = (url, params) => {
    return this.sendRequest(url, { method: "DELETE", params });
  };
}

export default HttpRequests;
