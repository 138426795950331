import { authTypes } from "../store/types";
import HttpRequests from "./httpRequests";
import url from "./url";
import { store } from "../store";
import axios from "axios";
import storage from "redux-persist/lib/storage";

const Request = new HttpRequests();
const baseUrl = url.account;
const { dispatch } = store;

const setToken = (token) => {
  return Promise.resolve().then(() => {
    localStorage.setItem("grupp.token", token);
  });
};

const handleLoginResponse = async (response, data, isChecked) => {
  const responseData = response.data;
  const cached = isChecked ? data.username : null;
  return Promise.all([
    setToken(responseData.accessToken),
    dispatch({ type: authTypes.SET_USER, payload: responseData }),
    dispatch({ type: authTypes.LOGIN_USER, payload: cached }),
  ]);
};

export const setFormInput = (values) => {
  return { type: authTypes.FORM_INPUT, payload: values };
};

export const loginUser = async (data, isChecked) => {
  try {
    const loginResponse = await Request.postWithoutHeader(
      `/${baseUrl}/login`,
      data
    );
    await handleLoginResponse(loginResponse, data, isChecked);
    return Promise.resolve(loginResponse);
  } catch (err) {
    dispatch({ type: authTypes.SET_USER, payload: { email: data.username } });
    return Promise.reject({
      statusCode: err.response.status,
      error: err.response.data,
    });
  }
};

export const logoutUser = (history) => async (dispatch) => {
  try {
    dispatch({ type: authTypes.LOGOUT_USER });
    Request.clearData();
    storage.removeItem("grupp.token");
    localStorage.removeItem("grupp.token");
    history.push("/login");
  } catch (err) {}
};

export const registerUser = async (data) => {
  try {
    const userResponse = await Request.postWithoutHeader(
      `/${baseUrl}/register`,
      data
    );
    if (userResponse.status) {
      dispatch({
        type: authTypes.SET_USER,
        payload: {
          id: userResponse.data?.id,
          email: userResponse.data?.email,
          firstName: userResponse.data?.firstName,
          lastName: userResponse.data?.lastName,
          verificationId: userResponse.data?.verificationId,
        },
      });
    }
    return Promise.resolve(userResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response.data.message || err.message,
    });
  }
};

export const resendVerificationEmail = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/resend-verification-email`,
      data
    );
    dispatch({
      type: authTypes.SET_USER,
      payload: { verificationId: response.data?.verificationId },
    });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Something went wrong",
    });
  }
};

export const handleEmailVerification = async (data) => {
  try {
    await Request.postWithoutHeader(`/${baseUrl}/verify-email`, data);
    return Promise.resolve(true);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Failed to verify otp",
    });
  }
};

export const handleForgotPassword = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/forgot-password`,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Failed to verify otp",
    });
  }
};

export const handleResetPassword = async (data) => {
  try {
    const response = await Request.postWithoutHeader(
      `/${baseUrl}/reset-password`,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message,
    });
  }
};

export const fetchStatesList = async () => {
  try {
    const response = await Request.get(`/location`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch state",
    });
  }
};

export const fetchLgaList = async (state) => {
  try {
    const lgaResponse = await Request.get(`/location/${state.toString()}`);
    return Promise.resolve(lgaResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch lga",
    });
  }
};

export const getProfile = async () => {
  try {
    const staffResponse = await Request.get(`/${url.base}/staff/profile`);
    return Promise.resolve(staffResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch staff",
    });
  }
};

export const updatePassword = async (data) => {
  try {
    const updateResponse = await Request.put(
      `${url.base}/account/change-password`,
      null,
      data
    );
    return Promise.resolve(updateResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response.data.message || "Network Error",
    });
  }
};

export const validateCheck = async (option, value) => {
  try {
    const response = await Request.post(
      `${url.base}/account/${option}/validate`,
      null,
      { value: value }
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response.data.message || "Failed to validate",
    });
  }
};

export const getUserInformation = async () => {
  try {
    const userResponse = await Request.get(`/${baseUrl}/me`);
    return Promise.resolve(userResponse);
  } catch (err) {
    return Promise.reject({ message: err.response.data.message });
  }
};

export const confirmPassword = async (data) => {
  try {
    const passwordResponse = await axios.post(
      `${process.env.REACT_APP_API}/${baseUrl}/login`,
      data
    );
    return Promise.resolve(passwordResponse);
  } catch (err) {
    return Promise.reject({ message: err.response?.data?.message });
  }
};

export const handleAuthorization = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/${baseUrl}/authorize`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
          browserId: `${localStorage.getItem("grupp.browserId")}`,
        },
      }
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({ message: err.response?.data.message });
  }
};

export const browserSignInRequest = async (data) => {
  try {
    const browserResponse = await axios.post(
      `${process.env.REACT_APP_API}/browser/request`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
        },
      }
    );
    return Promise.resolve(browserResponse.data);
  } catch (err) {
    return Promise.reject({ message: err.response?.data.message });
  }
};

export const browserValidation = async (data) => {
  try {
    const browserResponse = await axios.post(
      `${process.env.REACT_APP_API}/browser/validate`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
        },
      }
    );
    await handleLoginResponse(browserResponse.data);
    return Promise.resolve(browserResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data.message || "Something went wrong",
    });
  }
};

export const validateSubdomain = async (data) => {
  try {
    const subdoaminResponse = await Request.post(
      `/domain/validate`,
      null,
      data
    );
    return Promise.resolve(subdoaminResponse);
  } catch (err) {
    const statusCode = err.response?.status || 400;
    if (statusCode === 404) {
      return Promise.resolve({ status: true });
    }
    return Promise.reject({
      statusCode,
      message: err.response?.data?.message || "Failed to validate subdomain",
    });
  }
};

export const enableAutoSettlement = async (status) => {
  try {
    const response = await Request.put(`/${baseUrl}/${status}/auto-settlement`);
    dispatch({ type: authTypes.UPDATE_USER, payload: response.data });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response.data?.message || err.message,
    });
  }
};

export const handleRcNumberVerification = async (data) => {
  try {
    const verificationResponse = await Request.post(
      `/${baseUrl}/registration-number`,
      null,
      data
    );
    return Promise.resolve(verificationResponse);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data.message || "Failed to resolve BusinessReg No",
    });
  }
};

export const updateUsername = async (data) => {
  try {
    const usernameResponse = await Request.put(
      `/${url.base}/account/username`,
      null,
      data
    );
    dispatch({ type: authTypes.UPDATE_USER, payload: data });
    return Promise.resolve(usernameResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network Error",
    });
  }
};

export const handleUsernameRetrieval = async (data) => {
  try {
    const response = await Request.post(
      `/${baseUrl}/forget-username`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message,
    });
  }
};

export const updateBalanceConfiguration = async (data) => {
  try {
    const response = await Request.put(`${baseUrl}/balance-config`, null, data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to update",
    });
  }
};

export const updateAccount = async (data) => {
  try {
    const response = await Request.put(`/${url.account}/update`, null, data);
    dispatch({
      type: authTypes.UPDATE_USER,
      payload: {
        ...response.data,
        dob: response.data.dateOfBirth,
        phoneNumber: response.data.personalPhoneNumber,
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error?.response?.data?.message || "Failed to update",
    });
  }
};
