import { combineReducers } from "redux";
import authReducer from "./reducer/authReducer";
import analyticsReducer from "./reducer/analyticsReducer";
import settingsReducer from "./reducer/settingReducer";
import supportReducer from "./reducer/supportReducer";
import transactionReducer from "./reducer/transactionReducer";
import loanReducer from "./reducer/loanReducer";
import payoutReducer from "./reducer/cashpointReducer";
import walletReducer from "./reducer/walletReducer";
import terminalReducer from "./reducer/tmsReducer";
import businessReducer from "./reducer/businessReducer";
import subscriptionReducer from "./reducer/subscriptionReducer";

const Reducer = combineReducers({
  auth: authReducer,
  analytics: analyticsReducer,
  business: businessReducer,
  settings: settingsReducer,
  support: supportReducer,
  transaction: transactionReducer,
  loan: loanReducer,
  payout: payoutReducer,
  wallet: walletReducer,
  terminal: terminalReducer,
  subscription: subscriptionReducer,
});

export default Reducer;
