import numeral from "numeral";
import { authTypes } from "../../store/types";
import { message, notification } from "antd";
import { validateCheck } from "../../api/authApi";
import {
  Department,
  AccountType,
  commercialUserTypes,
  userTypesOption,
  CurrencyType,
  UserRole,
} from "./constants";
import dayjs from "dayjs";

export const TransactionType = {
  AIRTIME: "AIRTIME",
  WALLET_TOP_UP: "WALLET_TOP_UP",
  WITHDRAWAL: "WITHDRAWAL",
  CABLE_TV: "CABLE_TV",
  P2P: "P2P",
  PHCN: "PHCN",
  USSD_WITHDRAWAL: "USSD_WITHDRAWAL",
  DATA: "DATA",
  TRANSFER: "TRANSFER",
  DISBURSEMENT: "DISBURSEMENT",
  COMMISSION: "COMMISSION",
  SETTLEMENT: "SETTLEMENT",
  REVENUE: "REVENUE",
  PAYOUT: "PAYOUT",
  PURCHASE: "PURCHASE",
  LOAN: "LOAN",
  BETTING: "BETTING",
  LOAN_ALL: "LOAN_CREDIT,LOAN_REPAYMENT",
  NAIRA_INTL_WITHDRAWAL: "NAIRA_INTL_WITHDRAWAL",
  USD_INTL_WITHDRAWAL: "USD_INTL_WITHDRAWAL",
};

export const initialState = {
  startTime: dayjs().startOf("day").format("YYYY-MM-DD"),
  endTime: dayjs().endOf("day").format("YYYY-MM-DD"),
};

export const lastTwoMonths = {
  startTime: dayjs()
    .subtract(2, "months")
    .startOf("month")
    .format("YYYY-MM-DD"),
  endTime: dayjs().endOf("day").format("YYYY-MM-DD"),
};

export const lastTenDays = {
  startTime: dayjs().subtract(12, "days").startOf("day").format("YYYY-MM-DD"),
  endTime: dayjs().endOf("day").format("YYYY-MM-DD"),
};

export const lastSevenDays = {
  startTime: dayjs().subtract(6, "days").startOf("day").format("YYYY-MM-DD"),
  endTime: dayjs().endOf("day").format("YYYY-MM-DD"),
};

export const FormatMoney = (value, useDecimals, currencyType) => {
  let currency =
    currencyType === CurrencyType.USD
      ? "$"
      : process.env.REACT_APP_COUNTRY_CURRENCY;

  if (!value) return `${currency} 0`;

  if (typeof value === "string") value = Number(value);

  return `${currency} ${numeral(value).format(
    useDecimals ? "0,0,0.00" : "0,0,0"
  )}`;
};

export const FormatNumber = (inputValue) => {
  if (!inputValue) return 0;
  const stringValue = String(inputValue);
  return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const HumanFriendlyDate = (dateTime) => {
  if (!dateTime || dateTime === "NA") {
    return "N/A";
  }

  const _dateTime = dayjs(dateTime);
  return `${_dateTime.format("D MMM, YYYY")}`;
};

export const HumanFriendlyDateTime = (
  dateTime,
  format = "DD/MM/YYYY; HH:mm:ss"
) => {
  if (!dateTime || dateTime === "NA") {
    return "N/A";
  }
  const _dateTime = dayjs(dateTime).format(format);

  return _dateTime;
};

export const removeGMTFromTimeFormat = (value) => {
  if (!value || value === "NA") return "N/A";
  value = new Date(value).toDateString();
  return value;
};

export const isEmptyObj = (value) => {
  if (typeof value === "object") {
    return Object.keys(value).length !== 0;
  }
  return value;
};

export const capitalize = (value) => {
  if (typeof value !== "string") return "";
  if (value === TransactionType.PHCN || value === TransactionType.P2P) {
    return value;
  }
  const result = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return result.replaceAll("_", " ");
};

export const modifiedList = (list) => {
  const result = list.map((data) => ({
    name: `${data.firstName} ${data.lastName}`,
    phoneNumber: data.phoneNumber,
    amount: FormatMoney(data.totalAmount, true),
    totalPaid: FormatMoney(data.totalPaid, true),
    interest: FormatMoney(data.totalAmount, true),
    totalOwned: FormatMoney(data.totalOwned, true),
    repaymentStatus: data.repaymentStatus,
    repaymentDate: HumanFriendlyDate(list.repaymentDate),
    timeCreated: HumanFriendlyDate(data.timeCreated),
  }));
  return result;
};

export const checkRCPrefix = (str) => {
  if (!str.startsWith("RC")) {
    return "RC" + str;
  } else {
    return str;
  }
};

export const handleAccountType = (type) => {
  switch (type) {
    case AccountType.BUSINESS:
      return "Business Account";
    default:
      return "Individual Account";
  }
};

export const isTypeBills = (type) => {
  switch (type) {
    case TransactionType.TRANSFER:
    case TransactionType.AIRTIME:
    case TransactionType.CABLE_TV:
    case TransactionType.DATA:
    case TransactionType.PHCN:
    case TransactionType.BETTING:
      return true;
    default:
      return false;
  }
};

export async function copyText(text) {
  try {
    await navigator.clipboard.writeText(text);
    message.info({ content: "copied", duration: 3 });
  } catch {
    message.error({ content: "unable to copy", duration: 3 });
  }
}

export const modifiedFormRequest = (values, color) => {
  let subdomain = null;

  if (values.secondaryEmail || values.supportEmail) {
    values.businessSecondaryEmails = [
      values.secondaryEmail,
      values.supportEmail,
    ];

    delete values.secondaryEmail;
    delete values.supportEmail;
  }

  if (color && color?.r && color?.g && color?.b && color?.a) {
    values.color = {
      primaryColor: `rgb(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      secondaryColor: `rgb(${color.r}, ${color.g}, ${color.b}, ${0.1})`,
    };
  }

  if (values.customerSubdomain) {
    const parts = values.customerSubdomain.split(".");
    subdomain = parts[0];
  }

  delete values.customerSubdomain;
  delete values.email;

  return {
    values: values,
    subdomain,
  };
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const convertedSize = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
  return convertedSize + " " + sizes[i];
};

export function formatGraphValue(value) {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(0) + "B";
  }
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(0) + "K";
  } else {
    return value.toString();
  }
}

export const getTierLevel = (tier) => {
  const TIERS = {
    ONE: "Tier 1",
    TWO: "Tier 2",
    THREE: "Tier 3",
  };

  if (TIERS[tier]) return TIERS[tier];
  return tier;
};

export const modifyTransaction = (record, isWallet) => {
  if (isWallet) {
    const date = dayjs(record.timeCreated);
    const formattedDate = date.format("YYMMDDHHmmssSSS");
    const regex = /-(DEBIT|CREDIT)$/;
    const newReference = record.reference.replace(regex, "");
    return {
      ...record,
      timeCreated: formattedDate,
      reference: newReference,
      walletReference: record.reference,
    };
  }
  return record;
};

export const handleLoginError = (status, error, dispatch, history, value) => {
  switch (status) {
    case 403:
    case 406:
      dispatch({
        type: authTypes.SET_USER,
        payload: { id: error.data?.userId },
      });
      notification.error({
        message: "Oops! Your email is not verified.",
        duration: 6,
      });
      return history.push(`/verify-email/${error.data?.verificationId}`);
    case 451:
      Promise.all([
        dispatch({
          type: authTypes.SET_BROWSER_REQUEST_ID,
          payload: error?.message?.browserId || error?.message,
        }),
        dispatch({
          type: authTypes.SET_USER,
          payload: {
            profileImageId: error?.message?.profileImageId || "",
          },
        }),
      ]);

      notification.error({
        message: "This device is not recognized in our system.",
        duration: 6,
      });
      return history.push("/verify-device", { email: value.username });
    default:
      return notification.error({
        message: error?.message || "Login Failed",
        duration: 10,
      });
  }
};

export const handleKycAccess = (role, department, disableKycCheck) => {
  if (disableKycCheck) {
    return false;
  }
  switch (role) {
    case UserRole.ADMIN:
      return true;
    case UserRole.TEAM_LEAD:
      return (
        department === Department.BUSINESS || department === Department.SUPPORT
      );
    default:
      return false;
  }
};

export function addOneMonth(date) {
  var newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + 1);
  return newDate;
}

/**
 *
 * @param {Date} date1
 * @param {Date} date2
 * @param {"year"|"month"|"day"} limit
 * @returns
 */
export function areDatesSame(
  date1 = new Date(),
  date2 = new Date(),
  limit = "day"
) {
  let isSame = false;

  if (limit === "year") {
    isSame = date1.getFullYear() === date2.getFullYear();
  } else if (limit === "month") {
    isSame =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth();
  } else if (limit === "day") {
    isSame =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  return isSame;
}

export function isDateWithinRange(date, startDate, endDate) {
  return date >= startDate && date <= endDate;
}

export const beforeUpload = (file) => {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload PNG/JPG/JPEG/ file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export const removeEmptyNullValue = (value) => {
  for (const key in value) {
    if (value[key] === null || value[key] === undefined || value[key] === "") {
      delete value[key];
    }
    if (Array.isArray(value[key])) {
      value[key].length === 0 && delete value[key];
    }
  }
  return value;
};

/**
 *
 * @param {Array<{friendly: string, status: string}>} transactionStatusList
 * @param {Array<{ friendly: string, type: string}>} transactionTypesList
 */
export function getTransactionFilterOptions(
  transactionStatusList,
  transactionTypesList,
  isEnabled,
  customerTransaction
) {
  const TransactionStatusOptions = {
    label: "Transaction Status",
    fieldName: "status",
    options: transactionStatusList.map((item) => ({
      name: item.friendly,
      value: item.status,
    })),
  };

  const TransactionTypesOptions = {
    label: "Transaction Type",
    fieldName: "type",
    options: transactionTypesList.map((item) => ({
      name: item.friendly,
      value: item.type,
    })),
  };

  if (customerTransaction) {
    return {
      search: [{ label: "Biller ID", value: "customerBillerId" }],
      filters: [TransactionStatusOptions, TransactionTypesOptions],
    };
  }

  const SearchOptions = [
    { label: "Biller ID", value: "customerBillerId" },
    { label: "Phone No.", value: "phoneNumber" },
    { label: "Trans Ref.", value: "reference" },
  ];

  const UserTypesOptions = {
    label: "User Type",
    fieldName: "userType",
    options: isEnabled ? commercialUserTypes : userTypesOption,
  };

  return {
    search: SearchOptions,
    filters: [
      TransactionStatusOptions,
      UserTypesOptions,
      TransactionTypesOptions,
    ],
  };
}

export const modifyOptionWithImage = (bankList) => {
  const modifiedList = bankList.map((list) => ({
    label: (
      <div className="inline-flex w-full gap-3">
        <img
          className="object-contain"
          width={22}
          height={22}
          src={list.url}
          alt={list.name}
        />
        {list.name}
      </div>
    ),
    value: list.code,
    className: "rounded-md my-0.5",
  }));
  // This can be done better, any suggestion will be most helpful
  return modifiedList.concat({ label: "", value: "", disabled: true });
};

export const flagOption = (option) => {
  return option.map((list) => ({
    label: (
      <div className="inline-flex  gap-2 w-full font-[800] text-[#475467]">
        {list.icon && (
          <img
            className="object-contain"
            width={22}
            height={22}
            src={list.icon}
            alt={list.label}
          />
        )}
        {list.label}
      </div>
    ),
    value: list.value,
    className: "rounded-lg my-0.5",
  }));
};

export const validateNumber = (_, inputValue) => {
  const reg = /^-?\d*(\.\d*)?$/;
  if (reg.test(inputValue) || inputValue === "" || inputValue === "-") {
    return Promise.resolve(true);
  }
  return Promise.reject(new Error("Must be a number"));
};

export const handleUserOption = (list = []) =>
  list.map((item) => ({
    label: (
      <span>
        <span className="capitalize">
          {item.firstName} {item.lastName}
        </span>{" "}
        ({item.phoneNumber}) {item.username || ""}
      </span>
    ),
    value: item.id,
  }));

export const handleOptions = (options = [], label, value, includeAll) => {
  let modifiedOption = [];
  if (includeAll) {
    modifiedOption.push({
      label: "ALL",
      value: "ALL",
    });
  }
  for (let i = 0; i < options.length; i++) {
    modifiedOption.push({
      label: options[i][label],
      value: options[i][value],
      className: "capitalize",
    });
  }
  return modifiedOption;
};

export const validateEmailAndUsername = async (_, value) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const usernameRegex = /^[a-zA-Z0-9]{6,}$/;

  if (emailRegex.test(value) || usernameRegex.test(value)) {
    return Promise.resolve("success");
  } else {
    return Promise.reject(false);
  }
};

export async function validateUsername(username, setFeedback, form) {
  const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{7,}$/;
  if (regex.test(username)) {
    setFeedback((prev) => ({
      ...prev,
      username: true,
    }));
    try {
      const newValue = username.replace(/\s/g, "");
      const validateResponse = await validateCheck("username", newValue);
      form.setFieldValue("username", newValue);
      return Promise.resolve(validateResponse.status);
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(
      "Username must consist of at least 7 characters or alphanumeric but cannot consist of only numbers."
    );
  }
}

export function formatLabel(inputString) {
  if (typeof inputString !== "string") return;
  if (
    inputString === TransactionType.P2P ||
    inputString === TransactionType.PHCN
  ) {
    return inputString;
  }
  return inputString
    .split(/[_-]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const formatCurrency = (value) => {
  // Replace NGN or NAIRA with N
  value = value.replace(/NGN|NAIRA/g, "₦");

  // Replace USD with $
  value = value.replace(/USD/g, "$");

  return value;
};

export function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\s/g, "");

  phoneNumber = phoneNumber.replace(/^\+234/, "0");

  return phoneNumber;
}

export const appendImgUrl = (url) => {
  if (typeof url === "string" && url !== "NA") {
    return `data:image/jpeg;base64,${url}`;
  }
  return null;
};

export const formatDecimalPoint = (value) => {
  const spilttedValue = value.toString().split(".");
  if (spilttedValue.length > 1) {
    const decimalPart = spilttedValue[1].slice(0, 3);
    const hasThreeZerosAfterDecimal = decimalPart === "000";

    if (!hasThreeZerosAfterDecimal) {
      return `${spilttedValue[0]}.${decimalPart}`;
    }
  }
  return spilttedValue[0];
};

export const joinSubscriptionPricing = (defaultPlan, pricingList) => {
  const mergedArray = [];

  for (let i = 0; i < pricingList.length; i++) {
    const plan = pricingList[i];
    const setting = defaultPlan.find((s) => s.category === plan.category);
    mergedArray.push({
      ...setting,
      price: plan.price,
      enable: plan.enable,
    });
  }
  return mergedArray;
};

export function findHighestValue(array, key) {
  if (array.length === 0) {
    return 0;
  }
  let highest = array[0][key];

  for (let i = 1; i < array.length; i++) {
    if (array[i][key] > highest) {
      highest = array[i][key];
    }
  }

  return highest;
}

export const resolveDateOfBirth = async (_, value) => {
  const age = dayjs().diff(value, "years");
  if (age >= 18) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("You must be at least 18 years of old"));
};

export const getTspanGroups = (value, maxLineLength, x = 0, spaceY = 15) => {
  const words = value.split(" ");

  const assembleLines = words.reduce(
    (acc, word) => {
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      return {
        ...acc,
        currLine: acc.currLine + " " + word,
      };
    },
    { lines: [], currLine: "" }
  );
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);
  const lines = allLines.slice(0, 2);
  let children = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan x={x} dy={dy} key={i}>
        {lineText}
      </tspan>
    );
    dy += spaceY;
  });

  return children;
};

export const handleLogoAndLabel = (list) => {
  const cardList = list.flatMap((bank) =>
    bank.breakdown.map((card) => ({
      label: card.label,
      logo: card.logo,
    }))
  );

  const uniqueCardObj = cardList.reduce((acc, card) => {
    acc[card.label] = {
      ...card,
    };
    return acc;
  }, {});

  const cardKey = Object.keys(uniqueCardObj);
  const initalCardScheme = cardKey.reduce((obj, label) => {
    obj[label] = 0;
    return obj;
  }, {});

  return {
    uniqueCardObj,
    cardKey,
    initalCardScheme,
  };
};

export const CustomLabel = (data) => {
  const { x, value } = data;

  return (
    <g
      transform={`translate(${x},0)`}
      style={{
        opacity: 1,
      }}
    >
      <line
        x1="0"
        x2="0"
        y1="0"
        y2="0"
        style={{
          stroke: "rgb(119, 119, 119)",
          strokeWidth: 1,
        }}
      ></line>
      <text
        textAnchor="middle"
        transform="translate(0,5) rotate(0)"
        dominantBaseline="text-before-edge"
        fontSize="14px"
        fontStyle="normal"
        fill="#475467"
        style={{
          textAlign: "center",
          lineHeight: 18,
          fontFamily: "Avenir",
          outlineWidth: "0px",
          outlineColor: "transparent",
          textTransform: "capitalize",
          fontWeight: 400,
        }}
      >
        {getTspanGroups(value, 13)}
      </text>
    </g>
  );
};

export const decodeBase64 = (base64String) => {
  if (!base64String) return;
  return new TextDecoder().decode(
    Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0))
  );
};

export const downloadImage = (imageUrl, filename) => {
  const link = document.createElement("a");

  link.href = imageUrl;

  link.download = filename;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export function isValidSubdomain(subdomain) {
  const subdomainRegex = /^(?!-)(?!.*--)[a-z-]*(?<!-)$/;
  return subdomainRegex.test(subdomain);
}

function formatDate(date) {
  return date.toISOString().split("T")[0];
}

function getMessagePeriod(messageDate) {
  const currentDate = new Date();
  const formattedMessageDate = new Date(messageDate);

  const currentDateFormatted = formatDate(currentDate);
  const messageDateFormatted = formatDate(formattedMessageDate);

  const timeDiff = currentDate.getTime() - formattedMessageDate.getTime();
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (currentDateFormatted === messageDateFormatted) {
    return "Today";
  } else if (diffDays === 1) {
    return "Yesterday";
  } else if (diffDays < 7) {
    return formattedMessageDate.toLocaleDateString("en-US", {
      weekday: "long",
    });
  } else {
    return formattedMessageDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
}

export function timeDifferenceToString(dateString) {
  if (typeof dateString !== "string") {
    return "N/A";
  }
  const currentTime = new Date();
  const pastTime = new Date(dateString);
  const timeDiffInSeconds = Math.floor((currentTime - pastTime) / 1000);

  const timeUnits = [
    { unit: "wk", seconds: 604800 },
    { unit: "day", seconds: 86400 },
    { unit: "hr", seconds: 3600 },
    { unit: "min", seconds: 60 },
    { unit: "sec", seconds: 1 },
  ];

  for (const { unit, seconds } of timeUnits) {
    const count = Math.floor(timeDiffInSeconds / seconds);
    if (count >= 1) {
      return `${count} ${unit}${count > 1 ? "s" : ""} ago`;
    }
  }

  return "just now";
}

export const handleAccountFormField = (label) => {
  const classes =
    label !== "First Name" && label !== "Last Name" ? "md:col-span-2" : "";
  const newLabel = label === "BVN" ? "Bank Verification Number (BVN)" : label;

  return { classes, newLabel };
};

const groupChatByKey = (comment, isPublic) => {
  const negateKey = !isPublic ? "customer" : "gruppStaff";

  let messageList = [];
  for (let i = 0; i < comment.length; i++) {
    const messageObj = comment[i];
    const recipient = messageObj?.recipient;
    const doesExist = negateKey in messageObj;

    if (recipient === "grupp" && isPublic) {
      continue;
    }

    if (messageObj["org"] && recipient !== "grupp" && !isPublic) {
      continue;
    }

    if (!doesExist) {
      messageList.push(messageObj);
    }
  }
  return messageList;
};

export const handleCommentResponse = (list, publicMessage) => {
  const groupedResult = groupChatByKey(list, publicMessage);
  const modifiedResponse = groupedResult.reduce((acc, message) => {
    const period = getMessagePeriod(message.timeCreated);
    if (!acc[period]) {
      acc[period] = [];
    }
    acc[period].push(message);
    return acc;
  }, {});
  return modifiedResponse;
};

export const getObjValuesByKey = (obj, selected) => {
  let objValues = {};
  let canEdit = false;
  for (const key in obj) {
    const value = obj[key].values;
    const label = obj[key].label;

    if (label === selected) {
      canEdit = obj[key]?.canEdit || false;
      objValues = { ...value };
      break;
    }
  }
  return { objValues, canEdit };
};
