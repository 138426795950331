import { customerTypes } from "../types";

const initialState = {
  pageTab: 0,
  metrics: {
    active: 0,
    agentCount: 0,
    inactive: 0,
    merchantCount: 0,
    total: 0,
    pendingBusinessStatus: 0,
    pendingKyc: 0,
  },
  collapse: { open: false, className: "" },
  formCount: 0,
  aggRefetchCount: 0,
  windowSize: {
    width: undefined,
    height: undefined,
  },
  merchantUploadStatus: {
    show: false,
    title: null,
    text: null,
  },
  editModal: false,
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case customerTypes.SET_TAB:
      return { ...state, pageTab: action.payload };

    case customerTypes.SET_CUSTOMER_METRICS:
      return { ...state, metrics: action.payload };

    case customerTypes.TOOGLE_CONTENT:
      return { ...state, collapse: action.payload };

    case customerTypes.REFETCH_FORM_RESPONSE:
      return { ...state, formCount: state.formCount + 1 };
    case customerTypes.REFETCH_AGGREGATOR_LIST:
      return { ...state, aggRefetchCount: state.aggRefetchCount + 1 };

    case customerTypes.TOGGLE_EDIT_MODAL:
      return {
        ...state,
        editModal: !state.editModal,
      };

    case customerTypes.WINDOW_SIZE:
      return { ...state, windowSize: action.payload };
    case customerTypes.MERCHANT_UPLOAD_STATUS:
      return { ...state, merchantUploadStatus: action.payload };

    default:
      return state;
  }
};

export default businessReducer;
