import { store } from "../store";
import { customerTypes } from "../store/types";
import HttpRequest from "./httpRequests";
import url from "./url";

const Request = new HttpRequest();
const { dispatch } = store;

export const setPageTab = (tab) => {
  return dispatch({ type: customerTypes.SET_TAB, payload: tab });
};

export const handleCollapse = (payload) => {
  return dispatch({ type: customerTypes.TOOGLE_CONTENT, payload });
};

export const toogleEditModal = () => {
  return dispatch({ type: customerTypes.TOGGLE_EDIT_MODAL });
};

export const resizeWindow = (payload) => {
  return dispatch({ type: customerTypes.WINDOW_SIZE, payload });
};

export const fetchUserDetails = async (customerId, query) => {
  try {
    const userResponse = await Request.get(
      `/${url.account}/${customerId}/customer-details`,
      query
    );
    return Promise.resolve(userResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch customer",
    });
  }
};

export const fetchUserWalletTransaction = async (customerId, query) => {
  try {
    const response = await Request.get(
      `/${url.wallet}/${customerId}/customer-transaction`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch resource",
    });
  }
};

/*export const downLoadCustomerWalletReport = async (customerId, params, data) => {
  try {
    const response = await Requestspost(
      `/${url.wallet}/${customerId}/download`,
      params,
      data,
    );
     return Promise.resolve(response);
  } catch (error) {
      return Promise.reject({ message: error.response?.data?.message || "Failed to download resource"});
  }
};*/

export const fetchTerminals = async (query) => {
  try {
    const terminals = await Request.get(`/${url.terminal}`, query);
    return Promise.resolve(terminals);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const fetchTerminalMetrics = async () => {
  try {
    const metricsResponse = await Request.get(`/${url.terminal}/metrics`);
    return Promise.resolve(metricsResponse);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const generateRefCode = async (data) => {
  try {
    const codeResponse = await Request.post(
      `/${url.account}/codegen`,
      null,
      data
    );
    return Promise.resolve(codeResponse);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const sendReferralLink = async (data) => {
  try {
    const response = await Request.post(
      `/${url.account}/send-link`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    if (error.response)
      return Promise.reject({ message: error.response?.data?.message });
    return Promise.reject(error);
  }
};

export const fetchUsersMetrics = async (query) => {
  try {
    const users = await Request.get(`/${url.account}/customer-metrics`, query);
    dispatch({ type: customerTypes.SET_CUSTOMER_METRICS, payload: users.data });
    return Promise.resolve(users);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getPendingKyc = async (query) => {
  try {
    const listResponse = await Request.get(
      `/${url.account}/pending-kyc`,
      query
    );
    return Promise.resolve(listResponse);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const fetchAllUsers = async (query) => {
  try {
    const response = await Request.get(`/${url.account}/customers`, query);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch Customer",
    });
  }
};

export const downloadCustomerList = async (query) => {
  try {
    const downloadResponse = await Request.get(
      `/${url.account}/download-customers`,
      query
    );
    return Promise.resolve(downloadResponse);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const createSalesAggregator = async (data) => {
  try {
    const response = await Request.post(`/${url.aggregator}`, null, data);
    dispatch({ type: customerTypes.REFETCH_AGGREGATOR_LIST });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to create aggregator",
    });
  }
};

export const getAllSalesAggregator = async (query) => {
  try {
    const aggregator = await Request.get(`/${url.aggregator}`, query);
    return Promise.resolve(aggregator);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getSalesSummary = async () => {
  try {
    const summary = await Request.get(`/${url.aggregator}/summary`);
    return Promise.resolve(summary);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const downloadAggregator = async (query) => {
  try {
    const downloadResponse = await Request.get(
      `/${url.aggregator}/download-aggregators`,
      query
    );
    return Promise.resolve(downloadResponse);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getAggregatorCustomer = async (aggregatorId, query) => {
  try {
    const response = await Request.get(
      `/${url.aggregator}/${aggregatorId}/customers`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const updateAggregatorStatus = async (aggregatorId, data) => {
  try {
    const response = await Request.put(
      `/${url.aggregator}/${aggregatorId}/status`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const toggleAggregatorCreation = async (data) => {
  try {
    const response = await Request.put(
      `/${url.aggregator}/permit-create`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getAggregatorFee = async (query, aggregatorId) => {
  try {
    const response = await Request.get(
      `/${url.aggregator}/${aggregatorId}/fee`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const updateAggregatorFee = async (feeId, data) => {
  try {
    const response = await Request.put(
      `/${url.aggregator}/${feeId}/fee`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to update fee",
    });
  }
};

export const getAggregator = async (aggregatorId) => {
  try {
    const aggregatorResponse = await Request.get(
      `/${url.aggregator}/${aggregatorId}/details`
    );
    return Promise.resolve(aggregatorResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to get details",
    });
  }
};

export const toggleAggregatorCommission = async (data) => {
  try {
    const response = await Request.put(
      `/${url.aggregator}/permit-commission`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Netwoek Error",
    });
  }
};

export const resetAggregatorPassword = async (aggregatorId) => {
  try {
    const response = await Request.put(
      `/${url.aggregator}/${aggregatorId}/reset-password`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to reset password",
    });
  }
};

export const updateUserType = async (userType, data) => {
  try {
    const response = await Request.put(
      `/${url.account}/${userType}/type`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to update userType",
    });
  }
};

export const updateUserStatusByCustomerId = async (status, data) => {
  try {
    const response = await Request.put(
      `/${url.account}/${status}/flip-status`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const upgradeTransactionLevel = async (customerId, data) => {
  try {
    const upgradeResponse = await Request.put(
      `/${url.account}/${customerId}/transaction-level`,
      null,
      data
    );
    return Promise.resolve(upgradeResponse);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const fetchConsumerMetrics = async (query) => {
  try {
    const metrics = await Request.get(
      `/${url.account}/consumer-metrics`,
      query
    );
    return Promise.resolve(metrics);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const handleIdentityVerification = async (customerId, data) => {
  try {
    const response = await Request.put(
      `/${url.account}/${customerId}/approve`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const mapUsersToAggregator = async (aggregatorId, data) => {
  try {
    const response = await Request.put(
      `/${url.account}/${aggregatorId}/map-customer`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to move customers",
    });
  }
};

export const mapAggregatorToSuperAggregator = async (aggregatorId, data) => {
  try {
    const response = await Request.put(
      `/${url.account}/${aggregatorId}/map-aggregator`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const getAggregatorTransactionStats = async (aggregatorId, query) => {
  try {
    const response = await Request.get(
      `/${url.aggregator}/${aggregatorId}/parent-transaction-stats`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getAggregatorCustomerMetrics = async (aggregatorId, query) => {
  try {
    const response = await Request.get(
      `/${url.aggregator}/${aggregatorId}/customer-metrics`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch metrics",
    });
  }
};

export const getAggregatorCustomerTransactionStats = async (
  aggregatorId,
  query
) => {
  try {
    const response = await Request.get(
      `/${url.aggregator}/${aggregatorId}/customer-transaction-stats`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch resource",
    });
  }
};

export const fetchAllAggregators = async (query) => {
  try {
    const response = await Request.get(`/${url.aggregator}/lean`, query);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong ",
    });
  }
};

export const getSubAggregators = async (aggregatorId, query) => {
  try {
    const response = await Request.get(
      `/${url.aggregator}/${aggregatorId}/sub-aggregator`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const getSubAggregatorMetrics = async (aggregatorId) => {
  try {
    const response = await Request.get(
      `/${url.aggregator}/${aggregatorId}/parent-metrics`
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const getAllReferralList = async (params) => {
  try {
    const response = await Request.get(
      `/${url.account}/my-referrals`,
      params,
      null
    );
    return Promise.resolve(response);
  } catch (error) {
    if (error.response) {
      return Promise.reject({ message: error.response?.data?.message });
    }
    return Promise.reject(error);
  }
};

export const handleVirtualAccountRequest = async (customerId, data) => {
  try {
    const reponse = await Request.put(
      `/${url.account}/${customerId}/virtual-account`,
      null,
      data
    );
    return Promise.resolve(reponse);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const updateKycStatus = async (id, status, data) => {
  try {
    const response = await Request.put(
      `/${url.account}/${id}/${status}/kyc-approval-status`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to update KYC status",
    });
  }
};

export const mockMerchantUpload = async (formData) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const mockResponse = {
        data: {
          success: [{ value: "merchant1", error: null }],
          failed: [{ value: "merchant2", error: "Invalid data" }],
        },
      };
      resolve({ unwrap: () => mockResponse });
    }, 1000);
  });
};

export const handleMerchantUpload = (data) => {
  return dispatch({
    type: customerTypes.MERCHANT_UPLOAD_STATUS,
    payload: data,
  });
};

export const updateCustomerSettlementAccount = async ({ id, ...data }) => {
  try {
    const response = await Request.put(
      `/${url.account}/${id}/customer-settlement`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message || "Failed to update settlement account",
    });
  }
};

export const createCustomerSettlementAccount = async ({ id, ...data }) => {
  try {
    const response = await Request.post(
      `/${url.account}/${id}/customer-settlement`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message || "Failed to update settlement account",
    });
  }
};

export const deleteCustomerSettlementAccount = async (id, query) => {
  try {
    const response = await Request.delete(
      `/${url.account}/${id}/customer-settlement`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message || "Failed to delete settlement account",
    });
  }
};

export const updateCustomerVisibility = async (data) => {
  try {
    const response = await Request.put(
      `/${url.account}/customer-balance-config`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message || "Failed to update Wallet balance",
    });
  }
};

export const updateCustomerDetailById = async (id, data) => {
  try {
    const response = await Request.put(
      `/${url.account}/${id}/customer-details`,
      null,
      data
    );
    toogleEditModal();
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message,
    });
  }
};
