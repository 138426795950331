import React, { useEffect, useState, useCallback } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import LogoutModal from "../components/Modal/LogoutModal";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchBanks,
  fetchTransactionStatus,
  fetchTransactionTypes,
} from "../../api/transactionApi";
import { getTicketCategories } from "../../api/ticketApi";
import {
  closeTransactionModal,
  closeWalletTypeModal,
} from "../../api/walletApi";
import TransferOptionModal from "../components/Wallet/TransferOptionModal";
import TransferMoneyModal from "../components/Wallet/TransferMoneyModal";
import WalletTransferModel from "../components/Wallet/WalletTransferModel";
import { TransactionStatusModal } from "../components/Modal/StatusModal";
import TransactionDetail from "../components/Transaction/TransactionDetail";
import { useGetUser } from "../utils/Hooks";

const initialStatus = {
  open: false,
  message: "",
  description: "",
  data: {
    transactionReference: null,
    timeCreated: null,
  },
};

const AppShell = ({ children }) => {
  const [showLogout, setShowLogout] = useState(false);
  const { user: userProfile, isEnabled } = useGetUser();
  const pages = userProfile.pages;
  const location = useLocation();
  const walletModal = useSelector((s) => s.wallet);
  const { open = false, className = "" } = useSelector(
    (s) => s.business?.collapse
  );
  const [status, setStatus] = useState(initialStatus);
  const showTransaction = walletModal.showTransaction;

  function handleLogout() {
    setShowLogout((prev) => !prev);
  }

  function onClose() {
    setStatus((prev) => ({ ...prev, open: false }));
    closeWalletTypeModal();
  }

  const accessRoute = useCallback(() => {
    let path = location.pathname.toUpperCase();
    const isAuthorized = Boolean(pages?.find((res) => path.includes(res)));
    //if (!isAuthorized && isAuth) return history.push("/home");
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    async function getCachedData() {
      try {
        await Promise.all([
          fetchTransactionTypes(isEnabled),
          fetchTransactionStatus(),
          fetchBanks(),
          getTicketCategories(),
        ]);
      } catch (error) {
        console.log(error.message);
      }
    }
    getCachedData();
  }, [isEnabled]);

  useEffect(() => {
    accessRoute();
  }, [accessRoute]);

  return (
    <Layout>
      <Sidebar user={userProfile} open={open} />
      <Layout className={`site-layout ${className}`}>
        <Navbar
          className={className}
          logout={handleLogout}
          user={userProfile}
        />
        {children}
      </Layout>

      <LogoutModal show={showLogout} close={handleLogout} />

      {walletModal.isOpen && (
        <TransferOptionModal
          show={walletModal.isOpen}
          source={walletModal.source}
        />
      )}
      {walletModal.TRANSFER && (
        <TransferMoneyModal
          show={walletModal.TRANSFER}
          transferType={walletModal.transferType}
          source={walletModal.source}
          handleStatus={setStatus}
          close={closeWalletTypeModal}
        />
      )}

      {walletModal.WALLET && (
        <WalletTransferModel
          show={walletModal.WALLET}
          source={walletModal.source}
          destination={walletModal.destination}
          close={closeWalletTypeModal}
          setStatus={setStatus}
        />
      )}

      <TransactionStatusModal
        hasReference={Boolean(status.data)}
        status={status}
        close={onClose}
      />

      {showTransaction && (
        <TransactionDetail
          show={showTransaction}
          record={{
            reference: status.data.transactionReference,
            timeCreated: status.data.timeCreated,
          }}
          close={closeTransactionModal}
          shouldHide
        />
      )}
    </Layout>
  );
};

export default AppShell;
